<template>
    <div>
        <div class="commonInputTopBox">
            <pageHeader :pagename="'软件系统故障及异常配置管理'" :total="query.total" />
            <CommonHeaders
                :formConfig="formConfig"
                :formModel="formModel"
                :buttons="buttons"
                @button-click="handleButtonClick"
            ></CommonHeaders>
        </div>

        <div class="commonControl-body">
            <common-table
                :tableData="tableData"
                :tableButton="tableButton"
                :tableLabel="tableLabel"
                @edit="editFirstLevel"
            ></common-table>
        </div>
        <div class="layoutBorder">
            <Pagination
                :total="query.total"
                :page.sync="query.currentPage"
                @pagination="getList()"
                :limit.sync="query.pagesize"
            />
        </div>
    </div>
</template>

<script>
import {
    query,
} from '@/api/manage/operation/software_fault_managent/software_fault_managent.js';
import CommonTable from 'frontelementpackage/src/CommonTableButtonFixed.vue';
import CommonHeaders from '@/components/shibo_Common/CommonHeaders.vue';
import Pagination from '@/components/Pagination.vue';

export default {
    name: 'start',
    data() {
        return {
            formData: {},
            query: {
                querypage: 1, //获取页码
                pagesize: 10, //每页返回数量
                keyword: '', //搜索 可搜邮箱 用户名 手机号
                is_delete: false,
                content_type_code:undefined,
                total: 0,
            },
            tableData: [],
            dialogFormVisibleEdit: false,
            formEdit: { group_info: '' },
            dialogFormVisibleAdd: false,
            formAdd: {},
            tableLabel: [
                {
                    prop: 'id',
                    label: '系统ID',
                    minWidth: '10%'
                    // position:"right"
                },
                {
                    prop: 'product_name',
                    label: '系统软件产品名',
                    minWidth: '20%'
                },
                {
                    prop: 'product_type',
                    label: '软件系统所属产品分类',
                    minWidth: '20%'
                },
                // {
                //     prop: 'product_series',
                //     label: '产品型号',
                //     minWidth: '20%'
                // }
            ],
            tableButton: {
                width: 200,
                data: [
                    {
                        name: '点此进入操作',
                        type: 'info',
                        size: 'small'
                    }
                ]
            },
            value: null,
            optionValue: null,
            formConfig: [
                // {
                //     label: '关键字',
                //     prop: 'keyword',
                //     component: 'el-input',
                //     placeholder: '请输入关键词'
                // },
                // {
                //     label: '产品状态',
                //     prop: 'is_delete',
                //     component: 'el-select',
                //     placeholder: '请选择状态',
                //     options: [
                //         { label: '未删除', value: 'false' },
                //         { label: '已删除', value: 'true' }
                //     ]
                // },
                // {
                //     label: '产品分类',
                //     prop: 'product_type',
                //     component: 'el-select',
                //     placeholder: '请选择分类',
                //     options: [
                //         { label: '设备', value: 1 },
                //         { label: '软件', value: 2 }
                //     ]
                // }
            ],
            formModel: {},
            buttons: [
                // { label: '查询', type: 'primary', action: 'search' }
            ]
        };
    },
    components: {
        CommonTable,
        CommonHeaders,
        Pagination
    },
    created() {
        this.getFirstLevel();
        this.initOption();
    },
    methods: {
        initOption() {
            // product_classify_get({}).then(res => {
            //     if (res.code == 200) {
            //         this.value = res.data;
            //     } else {
            //         this.$message.info(res.msg);
            //     }
            // });
        },
        editFirstLevel(row, methods) {
            if (methods == '点此进入操作') {
                this.toInfo(row);
            }
        },
        toInfo(row) {
            this.$router.push({ name: 'software_Fault_Info', query: row });
            // this.$router.push({ path: '/operation/product_managent/info', query: row });
        },
        editUser(row) {
            this.formEdit = row;
            // console.log(this.form,"ddd")
            this.dialogFormVisibleEdit = true;
            // row;
            // this.$router.push({ name: "productLibraryInfo", query: { id: row.id } });
        },
        delUser(row) {
            row.id
            // let ids = row.id;
            // console.log({ group_id: ids });
            // this.$confirm('是否确定删除', '确认信息', {
            //     distinguishCancelAndClose: true,
            //     confirmButtonText: '删除',
            //     cancelButtonText: '放弃删除'
            // })
            //     .then(() => {
            //         product_set({
            //             product_id: ids, //产品ID
            //             is_delete: true
            //         }).then(res => {
            //             if (res.code == 200) {
            //                 this.$message.success('成功删除');
            //                 window.location.reload();
            //             } else {
            //                 this.$message.info(res.msg);
            //             }
            //         });
            //     })
            //     .catch(action => {
            //         this.$message({
            //             type: 'info',
            //             message: action === 'cancel' ? '放弃删除' : '停留在当前'
            //         });
            //     });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
            //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
            query(this.query).then(res => {
                console.log(this.tableData, 'new');
                this.tableData = res.data.result;
            });
        },
        handleSizeChange(val) {
            this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
            // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
            this.query = {
                querypage: this.currentPage,
                pagesize: this.pagesize,
                keyword: ''
            };
            console.log(this.query);
            query(this.query).then(res => {
                console.log(this.tableData, 'new');
                this.tableData = res.data.result;
            });
        },
        createFirstLevel() {
            // product_create(this.formAdd).then(res => {
            //     if (res.code == 200) {
            //         this.$message.success('成功添加');
            //         window.location.reload();
            //     } else {
            //         this.$message.info(res.msg);
            //     }
            // });
            // this.dialogFormVisibleAdd = false;
        },
        addFormButton() {
            this.dialogFormVisibleAdd = true;
        },
        updateFirstLevel() {
            // this.formEdit['product_id'] = this.formEdit['id'];
            // Reflect.deleteProperty(this.formEdit, 'id');
            // this.formEdit['product_type'] = this.optionValue;
            // console.log(this.formEdit);
            // product_edit(this.formEdit).then(res => {
            //     if (res.code == 200) {
            //         this.$message.success('成功修改');
            //         this.getFirstLevel();
            //     } else {
            //         this.$message.info(res.msg);
            //     }
            // });
            // this.dialogFormVisibleEdit = false;
        },
        getFirstLevel() {
            query(this.query).then(res => {
                this.total = res.data.result.length;
                this.tableData = res.data.result;
            });
        },
        handleFormSubmit(formData) {
            this.query.keyword = formData.keyword;
            this.query.is_delete = formData.is_delete;
            this.query.product_type = formData.product_type;
            query(this.query).then(res => {
                this.total = res.data.total;
                this.tableData = res.data.result;
            });
        },
        handleButtonClick(button, formData) {
            if (button.action === 'search') {
                this.handleFormSubmit(formData);
            } else if (button.action === 'create') {
                this.addFormButton();
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
